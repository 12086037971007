import { CheckoutPaymentFrequency } from '@azos/core'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'

import { BillingServiceSource } from './payments'
import { StatusColor } from './status'

export type ProposalStatusResponse = {
  color: StatusColor
  label: TextProposalStatus
  type: string
}

export enum TextProposalStatus {
  draft = 'Rascunho',
  sign_process = 'Aguardando assinatura',
  info_completed = 'Aguardando pagamento',
  in_analysis = 'Em análise',
  signed = 'Assinado',
  pending_payment = 'Aguardando pagamento',
  accepted = 'Aceita',
  refused = 'Recusada',
  expired = 'Expirada',
  filed = 'Arquivada',
}

export type ProposalCoverageResponse = {
  code: CoveragesCode
  label: string
  value: string
}

export type ProposalsResponse = {
  proposalId: string
  number: string
  status: ProposalStatusResponse
  premium: string
  created: string
  coverages: ProposalCoverageResponse[]
  url: string
  frequency: CheckoutPaymentFrequency
}

export type ProposalsDetailsResponse = {
  proposalId: string
  proposalNumber: string
  billingServiceSource: BillingServiceSource
  status: ProposalStatusResponse
  coverages: ProposalCoverageResponse[]
  url: string
}
