import { CoveragesCode } from '@azos/shared/src/lib/coverages'

type BenefitActionResponse = {
  id: number
  label: string
  externalLink: string
  variant: 'primary' | 'secondary'
}

export enum ProductCardShowOnlyFor {
  ALL = 'Todos',
  B2C = 'Clientes B2C',
  B2B = 'Clientes B2B',
}

type BenefitMetatagsResponse = {
  id: number
  icon: string
  title: string
  value: string
  helpText: string
}

export type BenefitBadgeResponse = {
  icon: string
  label: string
  backgroundColor: string
  textColor: string
}

type BenefitImageFormatResponse = Partial<{
  thumbnail: string
  small: string
  medium: string
}>

export type ListBenefitResponse = {
  id: number
  alert?: string
  copy?: string
  title: string
  subtitle: string
  description: string
  fullDescription: string
  cardImage: string
  cardImageFormats?: BenefitImageFormatResponse
  image: string
  slug: string
  imageFormats?: BenefitImageFormatResponse
  backgroundColor: string
  badge?: BenefitBadgeResponse
  actions?: BenefitActionResponse[]
  metatags?: BenefitMetatagsResponse[]
}

export enum ListNewsBannersType {
  benefits = 'benefits',
  appDownload = 'app-download',
}

export type ListNewsBannersLink = { externalLink: string }
export type ListNewsBannerData = ListBenefitResponse | ListNewsBannersLink

export type ListNewsBannersResponse = {
  backgroundColor: string
  data: ListNewsBannerData
  image: string
  logo: string
  logoText: string
  description: string
  type: ListNewsBannersType
}

export type ListSimpleCardsResponse = {
  iconName: string
  title: string
  order: number
}

export type ProductDetailResponse = {
  iconName: string
  title: string
  description: string
  buttonText: string
  productCards: ListSimpleCardsResponse[]
  image: string
}

export type ListProductCardsResponse = {
  iconName: string
  title: string
  productCode: CoveragesCode
  appProductDetail: ProductDetailResponse | null
  showOnlyFor: ProductCardShowOnlyFor
  order: number
  isNew: boolean
  image: string
  cardImage: string
}
