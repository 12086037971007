import { CoveragesCode } from '@azos/shared/src/lib/coverages'

const getCoverageValue = (code: CoveragesCode) => {
  switch (code) {
    case CoveragesCode.COD_0001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_1001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2010_SEGURO_DE_VIDA:
      return {
        icon: 'icon-heart',
        name: 'Seguro de Vida',
        slug: 'Morte',
      }
    case CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_1002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_2002_ASSISTENCIA_FUNERAL:
      return {
        icon: 'icon-dove',
        name: 'Assistência Funeral Individual',
        slug: 'SAF',
      }
    case CoveragesCode.COD_1009_AFF:
    case CoveragesCode.COD_2009_AFF:
      return {
        icon: 'icon-dove',
        name: 'Assistência Funeral Familiar',
        slug: 'SAFF',
      }
    case CoveragesCode.COD_0003_INVALIDEZ:
    case CoveragesCode.COD_1003_INVALIDEZ:
    case CoveragesCode.COD_2003_INVALIDEZ:
    case CoveragesCode.COD_2013_INVALIDEZ:
      return {
        icon: 'icon-wheelchair',
        name: 'Invalidez Total por Acidente',
        slug: 'IPTA',
      }
    case CoveragesCode.COD_0004_DOENCAS_GRAVES:
      return {
        icon: 'icon-virus',
        name: 'Doenças Graves',
        slug: 'DG',
      }
    case CoveragesCode.COD_0005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_1005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2011_DOENCAS_GRAVES_10:
      return {
        icon: 'icon-virus',
        name: 'Doenças Graves 10',
        slug: 'DG10',
      }
    case CoveragesCode.COD_2015_DOENCAS_GRAVES_13:
      return {
        icon: 'icon-virus',
        name: 'Doenças Graves 30',
        slug: 'DG30',
      }
    case CoveragesCode.COD_0006_DIH:
    case CoveragesCode.COD_1006_DIH:
    case CoveragesCode.COD_2006_DIH:
      return {
        icon: 'icon-hospital',
        name: 'Diária de Internação Hospitalar',
        slug: 'DIH',
      }
    case CoveragesCode.COD_2007_RIT:
      return {
        icon: 'icon-crutches',
        name: 'Renda por Incapacidade Temporária',
        slug: 'RIT',
      }
    case CoveragesCode.COD_2008_CIRURGIAS:
      return {
        icon: 'icon-stethoscope',
        name: 'Cirurgias',
        slug: 'Cirurgias',
      }
    case CoveragesCode.COD_2012_MAC:
      return {
        icon: 'icon-heart',
        name: 'Morte acidental',
        slug: 'MAC',
      }
    case CoveragesCode.COD_2014_IPTA_ESTENTIDA:
      return {
        icon: '',
        name: 'IPTA Estendida',
        slug: 'IPTAE',
      }
  }
}

export { getCoverageValue }
