import React from 'react'

import { useRemoteConfig } from '@azos/core'
import nookies from 'nookies'

export const MaintenanceRedirect = () => {
  const { get, isFetchingConfig } = useRemoteConfig()

  const cookies = nookies.get(null)
  const allowMaintenanceAccess = cookies?.['@azos/allowMaintenanceAccess']

  const shouldRedirectToMaintenance = get('MAINTENANCE_B2C_WEBAPPS_DASHBOARD')

  React.useEffect(() => {
    if (
      !isFetchingConfig &&
      shouldRedirectToMaintenance &&
      allowMaintenanceAccess !== 'true'
    ) {
      window.location.href = 'https://www.azos.com.br/manutencao-app'
    }
  }, [shouldRedirectToMaintenance, allowMaintenanceAccess, isFetchingConfig])

  return null
}

export default MaintenanceRedirect
