import { CoveragesCode } from '@azos/shared/src/lib/coverages'

export { CoveragesCode }

export interface Coverage {
  code: string
  label: string
  value: string
}

export type PolicyDetailsCoverageResponse = {
  code: CoveragesCode
  label: string
  insuredCapital: string
  insuredCapitalValue: number
  icon: string
  order: number
}
